export const AssuncaoActionsTypes = {
  LIST_BLACKLISTED_QUOTAS_REQUEST: '@@assuncao/LIST_BLACKLISTED_QUOTAS_REQUEST',
  LIST_BLACKLISTED_QUOTAS_SUCCESS: '@@assuncao/LIST_BLACKLISTED_QUOTAS_SUCCESS',
  LIST_BLACKLISTED_QUOTAS_ERROR: '@@assuncao/LIST_BLACKLISTED_QUOTAS_ERROR',

  CREATE_BLACKLISTED_QUOTA_REQUEST: '@@assuncao/CREATE_BLACKLISTED_QUOTA_REQUEST',
  CREATE_BLACKLISTED_QUOTA_SUCCESS: '@@assuncao/CREATE_BLACKLISTED_QUOTA_SUCCESS',
  CREATE_BLACKLISTED_QUOTA_ERROR: '@@assuncao/CREATE_BLACKLISTED_QUOTA_ERROR',

  DELETE_BLACKLISTED_QUOTA_REQUEST: '@@assuncao/DELETE_BLACKLISTED_QUOTA_REQUEST',
  DELETE_BLACKLISTED_QUOTA_SUCCESS: '@@assuncao/DELETE_BLACKLISTED_QUOTA_SUCCESS',
  DELETE_BLACKLISTED_QUOTA_ERROR: '@@assuncao/DELETE_BLACKLISTED_QUOTA_ERROR',
}

export interface IAssuncaoState {
  blacklistData: {
    error: boolean
    loading: boolean
    items?: any[]
    quotas?: any[]
    createdAt?: string
    total?: number
  }
  changeCategoryData: {
    error: boolean
    loading: boolean
  }
}
