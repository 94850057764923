import apiV2 from 'store/utils/apiv2'
import { put, call, takeLatest, all, fork } from 'redux-saga/effects'
import {
  getBlacklistSuccess,
  getBlacklistFailure,
  createBlacklistSuccess,
  createBlacklistFailure,
  deleteBlacklistSuccess,
  deleteBlacklistFailure,
} from './actions'
import { BlacklistData, BlacklistParams } from './interface'
import { AssuncaoActionsTypes } from './types'

export const getBlacklist = async (params: BlacklistParams) => {
  try {
    const { filters, ...rest } = params
    params = { ...rest, operation: filters?.join(',') }
    const response = await apiV2.get(`/api/assuncao/blacklist/findall?${new URLSearchParams(params as any).toString()}`)
    return response.data || response
  } catch (error) {
    throw error
  }
}

export const createBlacklist = async (data: BlacklistData) => {
  return await apiV2.post(`/api/assuncao/blacklist/create`, data)
}

export const deleteBlacklist = async (payload: { operation: string; quotas: string[] }) => {
  return await apiV2.delete(`/api/assuncao/blacklist`, { data: payload })
}

function* fetchBlacklistedQuotas(action: { type: string; payload: BlacklistParams }) {
  try {
    const res: any = yield call(getBlacklist, action.payload)

    if (!res || !res.items) {
      throw new Error('Invalid API response')
    }

    yield put(getBlacklistSuccess({ ...res, items: res.items || [] }))
  } catch (err) {
    yield put(getBlacklistFailure())
  }
}

function* createBlacklistSaga(action: { type: string; payload: BlacklistData }) {
  try {
    yield call(createBlacklist, action.payload)
    yield put(createBlacklistSuccess())
  } catch (err) {
    yield put(createBlacklistFailure())
  }
}

function* deleteBlacklistSaga(action: { type: string; payload: { operation: string; quotas: string[] } }) {
  try {
    yield call(deleteBlacklist, { operation: action.payload.operation, quotas: action.payload.quotas })
    yield put(deleteBlacklistSuccess())
  } catch (err) {
    yield put(deleteBlacklistFailure())
  }
}

function* watchFetchBlacklist() {
  yield takeLatest(AssuncaoActionsTypes.LIST_BLACKLISTED_QUOTAS_REQUEST, fetchBlacklistedQuotas)
}

function* watchCreateBlacklist() {
  yield takeLatest(AssuncaoActionsTypes.CREATE_BLACKLISTED_QUOTA_REQUEST, createBlacklistSaga)
}

function* watchDeleteBlacklist() {
  yield takeLatest(AssuncaoActionsTypes.DELETE_BLACKLISTED_QUOTA_REQUEST, deleteBlacklistSaga)
}

export default function* assuncaoSagas() {
  yield all([fork(watchFetchBlacklist), fork(watchCreateBlacklist), fork(watchDeleteBlacklist)])
}
