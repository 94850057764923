import { action } from 'typesafe-actions'
import { AssuncaoActionsTypes } from './types'
import { BlacklistData, BlacklistParams } from './interface'

export const getBlacklistRequest = (payload: BlacklistParams) =>
  action(AssuncaoActionsTypes.LIST_BLACKLISTED_QUOTAS_REQUEST, payload)

export const getBlacklistSuccess = (data: any) => action(AssuncaoActionsTypes.LIST_BLACKLISTED_QUOTAS_SUCCESS, data)

export const getBlacklistFailure = () => action(AssuncaoActionsTypes.LIST_BLACKLISTED_QUOTAS_ERROR)

export const createBlacklistRequest = (payload: BlacklistData) =>
  action(AssuncaoActionsTypes.CREATE_BLACKLISTED_QUOTA_REQUEST, payload)

export const createBlacklistSuccess = () => action(AssuncaoActionsTypes.CREATE_BLACKLISTED_QUOTA_SUCCESS)

export const createBlacklistFailure = () => action(AssuncaoActionsTypes.CREATE_BLACKLISTED_QUOTA_ERROR)

export const deleteBlacklistRequest = (payload: string) =>
  action(AssuncaoActionsTypes.DELETE_BLACKLISTED_QUOTA_REQUEST, payload)

export const deleteBlacklistSuccess = () => action(AssuncaoActionsTypes.DELETE_BLACKLISTED_QUOTA_SUCCESS)

export const deleteBlacklistFailure = () => action(AssuncaoActionsTypes.DELETE_BLACKLISTED_QUOTA_ERROR)
