import { action } from 'typesafe-actions'
import { BtgActionsTypes } from './types'
import { AutomaticDebitParams, AutomaticDebitData } from './interface'

export const getAutomaticDebitRequest = (payload: AutomaticDebitParams) =>
  action(BtgActionsTypes.LIST_AUTOMATIC_DEBIT_REQUEST, payload)

export const getAutomaticDebitSuccess = (data: { items: AutomaticDebitData[]; total: number }) =>
  action(BtgActionsTypes.LIST_AUTOMATIC_DEBIT_SUCCESS, data)

export const getAutomaticDebitFailure = () => action(BtgActionsTypes.LIST_AUTOMATIC_DEBIT_FAILURE)
