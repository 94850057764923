import { Action, PayloadAction, TypeConstant } from 'typesafe-actions'
import { IBtgState, BtgActionsTypes } from './types'

const initialState: IBtgState = {
  automaticDebitData: {
    error: false,
    loading: false,
    items: [],
    total: 0,
  },
}

export const btgReducer = (
  state: IBtgState = initialState,
  action: Action<TypeConstant> & PayloadAction<TypeConstant, any>,
): IBtgState => {
  switch (action.type) {
    case BtgActionsTypes.LIST_AUTOMATIC_DEBIT_REQUEST:
      return {
        ...state,
        automaticDebitData: {
          ...state.automaticDebitData,
          loading: true,
          error: false,
        },
      }

    case BtgActionsTypes.LIST_AUTOMATIC_DEBIT_SUCCESS:
      return {
        ...state,
        automaticDebitData: {
          ...state.automaticDebitData,
          items: (action.payload.items || []).map((item: any) => ({
            _id: item._id,
            cpf_cnpj: item.cpf_cnpj,
            cod_cota: item.cod_cota,
            cod_grupo: item.cod_grupo,
            operacao: item.operacao,
            agencia: item.agencia,
            conta: item.conta,
            adesao_ao_debito: item.adesao_ao_debito,
            proximo_debito: item.proximo_debito,
            status: item.status,
            motivo: item.motivo,
          })),
          total: action.payload.total,
          loading: false,
        },
      }

    case BtgActionsTypes.LIST_AUTOMATIC_DEBIT_FAILURE:
      return {
        ...state,
        automaticDebitData: {
          ...state.automaticDebitData,
          error: true,
          loading: false,
        },
      }

    default:
      return state
  }
}
