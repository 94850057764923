import { Action, PayloadAction, TypeConstant } from 'typesafe-actions'
import { IAssuncaoState, AssuncaoActionsTypes } from './types'

const initialState: IAssuncaoState = {
  blacklistData: {
    error: false,
    loading: false,
    items: [],
    total: 0,
  },
  changeCategoryData: {
    error: false,
    loading: false,
  },
}

export const assuncaoReducer = (
  state: IAssuncaoState = initialState,
  action: Action<TypeConstant> & PayloadAction<TypeConstant, any>,
): IAssuncaoState => {
  switch (action.type) {
    case AssuncaoActionsTypes.LIST_BLACKLISTED_QUOTAS_REQUEST:
      return {
        ...state,
        blacklistData: {
          ...state.blacklistData,
          loading: true,
          error: false,
        },
      }

    case AssuncaoActionsTypes.LIST_BLACKLISTED_QUOTAS_SUCCESS:
      return {
        ...state,
        blacklistData: {
          ...state.blacklistData,
          items: action.payload.items || [],
          quotas: action.payload.quotas || [],
          createdAt: action.payload.createdAt || '',
          total: action.payload.total ?? state.blacklistData.total,
          loading: false,
        },
      }

    case AssuncaoActionsTypes.LIST_BLACKLISTED_QUOTAS_ERROR:
      return {
        ...state,
        blacklistData: {
          ...state.blacklistData,
          error: true,
          loading: false,
        },
      }

    case AssuncaoActionsTypes.CREATE_BLACKLISTED_QUOTA_REQUEST:
      return {
        ...state,
        changeCategoryData: {
          ...state.changeCategoryData,
          loading: true,
          error: false,
        },
      }

    case AssuncaoActionsTypes.CREATE_BLACKLISTED_QUOTA_SUCCESS:
      return {
        ...state,
        changeCategoryData: {
          ...state.changeCategoryData,
          loading: false,
        },
      }

    case AssuncaoActionsTypes.CREATE_BLACKLISTED_QUOTA_ERROR:
      return {
        ...state,
        changeCategoryData: {
          ...state.changeCategoryData,
          error: true,
          loading: false,
        },
      }

    case AssuncaoActionsTypes.DELETE_BLACKLISTED_QUOTA_REQUEST:
      return {
        ...state,
        changeCategoryData: {
          ...state.changeCategoryData,
          loading: true,
          error: false,
        },
      }

    case AssuncaoActionsTypes.DELETE_BLACKLISTED_QUOTA_SUCCESS:
      return {
        ...state,
        changeCategoryData: {
          ...state.changeCategoryData,
          loading: false,
        },
      }

    case AssuncaoActionsTypes.DELETE_BLACKLISTED_QUOTA_ERROR:
      return {
        ...state,
        changeCategoryData: {
          ...state.changeCategoryData,
          error: true,
          loading: false,
        },
      }

    default:
      return state
  }
}
