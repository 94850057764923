export const MODULESCONFIG_RESOURCE = '/modulos'
export const PERMISSION_RESOURCE = '/permissoes'
export const REPASSE_RESOURCE = '/repasse'
export const HOME_EQUITY_RESOURCE = '/home-equity'
export const DISTRIBUICAO_LEADS_RESOURCE = '/leads'
export const UNIDADE_VENDAS_RESOURCE = '/unidade-vendas'
export const ANTECIPAY_RESOURCE = '/antecipay'
export const DADOS_KPIS_RESOURCE = '/kpis'
export const INADIMPLENCIA_RETENCAO = '/inadimplencia'
export const PREVIAS_RESOURCE = '/previas'
export const ECOMMERCE_RESOURCE = '/e-commerce'
export const COMUNICACAO_RESOURCE = '/comunicacao'
export const ASSEMBLY_RESOURCE = '/assembly'
export const INSURANCE_RESOURCE = '/insurance'
export const LP_UNITY_RESOURCE = '/lp-unity'
export const VERSIONING_APP_RESOURCE = '/versionamentoapp'
export const CAAS_RESOURCE = '/caas'
export const SALES_MACHINE_RESOURCE = '/sales-machine'
export const CONTEMPLAY_RESOUCE = '/contemplay'
export const POST_SALES_RESOUCE = '/pos-vendas'
export const COTACASH_RESOURCE = '/cotacash'
export const APP_CLIENTE_RESOURCE = '/app-cliente'
export const RECORRENCIA_RESOURCE = '/recorrencia'
export const RECORRENCIA_CONSULTA_RESOURCE = '/recorrencia/consulta'
export const MATRICULAS = '/matriculas'
export const COTA_EQUITY = '/cota-equity'
export const ASSUNCAO_RESOURCE = '/assuncao'
export const BTG_RESOURCE = '/btg/debitos'

export const GROWTH_RESOURCE = 'https://api.mktademicon.com.br/api/antecipay/painel'
