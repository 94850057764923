import React, { lazy } from 'react'
import { MenuItems } from 'routes/Routes'
import * as Icons from '@material-ui/icons'
import { buildRouteAndSubRoute } from 'core/dynamic-component'
import { BTG_RESOURCE } from 'shared/resources'

const BtgList = lazy(() => import('pages/Btg/Debitos'))

export const BtgRoutes: MenuItems[] = [
  {
    path: '',
    name: 'BTG',
    iconComponent: Icons.List,
    menus: [
      {
        path: `${BTG_RESOURCE}`,
        name: 'Lista de Débitos',
        component: BtgList,
        exact: true,
        menus: [],
      },
    ],
  },
]

/* eslint-disable-next-line react/display-name */
export default () => <>{buildRouteAndSubRoute(BtgRoutes)}</>
