import { all, fork } from 'redux-saga/effects'
import authSaga from './auth/sagas'
import modulesConfigSagas from './modules_config/sagas'
import permissionsSagas from './permissions/sagas'
import rolesSagas from './roles/sagas'
import negotiationsSagas from './repasse/negotiations/sagas'
import userSagas from './user/sagas'
import previasSagas from './previas/sagas'
import retencaoSagas from './inadimplencia/sagas'
import antecipaySagas from './antecipay/sagas'
import comunicacaoSagas from './comunicacao/sagas'
import assemblySagas from './assembly/sagas'
import assemblyMotorsSagas from './assembly/motors/sagas'
import insuranceSagas from './insurance/sagas'
import insuranceSalesConstantsSagas from './insuranceSaleConsultants/sagas'
import whatsAppSagas from './whats_app/sagas'
import lpUnitySagas from './lp_unity/sagas'
import caasSagas from './caas_digital/sagas'
import cotaEquitySagas from './cota-equity/sagas'
import salesMachineSagas from './sales_machine/sagas'
import postSalesSagas from './post_sales/sagas'
import versionAppSagas from './versioningApp/sagas'
import handlerGetTransactions from './recorrencia/sagas'
import contemplaySagas from './contemplay/sagas'
import assuncaoSagas from './assuncao/sagas'
import btgSagas from './btg/sagas'

export function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(negotiationsSagas),
    fork(permissionsSagas),
    fork(modulesConfigSagas),
    fork(rolesSagas),
    fork(userSagas),
    fork(previasSagas),
    fork(retencaoSagas),
    fork(antecipaySagas),
    fork(comunicacaoSagas),
    fork(assemblySagas),
    fork(assemblyMotorsSagas),
    fork(insuranceSagas),
    fork(insuranceSalesConstantsSagas),
    fork(whatsAppSagas),
    fork(lpUnitySagas),
    fork(caasSagas),
    fork(cotaEquitySagas),
    fork(salesMachineSagas),
    fork(postSalesSagas),
    fork(versionAppSagas),
    fork(handlerGetTransactions),
    fork(contemplaySagas),
    fork(assuncaoSagas),
    fork(btgSagas),
  ])
}
