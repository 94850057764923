import React, { lazy, Suspense } from 'react'
import PrivateRoute from 'routes/PrivateRoute'
import { Switch } from 'react-router-dom'
import RepasseModule from 'routes/Modules/Repasse'
import HomeEquityModule from 'routes/Modules/HomeEquity'
import ModulesConfigModule from 'routes/Modules/ModulesConfig'
import PermissionsModule from 'routes/Modules/Permissions'
import DistribuicaoLeadsRoutes from 'routes/Modules/DistribuicaoLeads'
import UnidadeVendasRoutes from 'routes/Modules/UnidadesVenda'
import PreviasRoutes from 'routes/Modules/Previas'
import EcommerceRoutes from 'routes/Modules/Ecommerce'
import KpisRoutes from 'routes/Modules/Kpis'
import InadimpleciaRoutes from 'routes/Modules/Inadimplencia'
import AntecipayRoutes from 'routes/Modules/Antecipay'
import ComunicacaoRoutes from 'routes/Modules/Comunicacao'
import AssembleiaRoutes from 'routes/Modules/Assembleias'
import SegurosRoutes from 'routes/Modules/Seguros'
import LpUnidadesRoutes from 'routes/Modules/LpUnity'
import CaasRoutes from 'routes/Modules/Caas'
import VersioningApp from 'routes/Modules/VersioningApp'
import SalesMachineRoutes from 'routes/Modules/SalesMachine'
import ContemplayRoutes from 'routes/Modules/Contemplay'
import PosVendasRoutes from 'routes/Modules/PosVendas'
import CotaCashRoutes from 'routes/Modules/CotaCash'
import AppClienteRoutes from 'routes/Modules/AppCliente'
import RecorrenciaRoutes from 'routes/Modules/Recorrencia'
import MatriculasRoutes from 'routes/Modules/Matriculas'
import CotaEquityRoutes from 'routes/Modules/CotaEquity'
import AssuncaoRoutes from 'routes/Modules/Assuncao'
import BtgRoutes from 'routes/Modules/Btg'

const Dash = lazy(() => import('pages/Dash'))
const DynamicComponent = lazy(() => import('core/dynamic-component'))

export default function DashboardRoutes() {
  return (
    <Suspense fallback={''}>
      <Switch>
        <PrivateRoute path="/" exact component={Dash} />
        <PrivateRoute exact path="/externo/:id" component={DynamicComponent} />
      </Switch>
      {/* MODULES */}
      <RepasseModule />
      <HomeEquityModule />
      <DistribuicaoLeadsRoutes />
      <UnidadeVendasRoutes />
      <PreviasRoutes />
      <AntecipayRoutes />
      <KpisRoutes />
      <InadimpleciaRoutes />
      <PermissionsModule />
      <ModulesConfigModule />
      <EcommerceRoutes />
      <ComunicacaoRoutes />
      <AssembleiaRoutes />
      <SegurosRoutes />
      <LpUnidadesRoutes />
      <CaasRoutes />
      <VersioningApp />
      <SalesMachineRoutes />
      <ContemplayRoutes />
      <PosVendasRoutes />
      <CotaCashRoutes />
      <AppClienteRoutes />
      <RecorrenciaRoutes />
      <MatriculasRoutes />
      <CotaEquityRoutes />
      <AssuncaoRoutes />
      <BtgRoutes />
    </Suspense>
  )
}
