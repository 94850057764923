import apiV2 from 'store/utils/apiv2'
import { put, call, takeLatest, all, fork } from 'redux-saga/effects'
import { getAutomaticDebitSuccess, getAutomaticDebitFailure } from './actions'
import { AutomaticDebitParams } from './interface'
import { BtgActionsTypes } from './types'

export const getAutomaticDebit = async (params: AutomaticDebitParams) => {
  try {
    const response = await apiV2.get(`/api/btg/automatic-debit-list?${new URLSearchParams(params as any).toString()}`)
    return response
  } catch (error) {
    throw error
  }
}

function* fetchAutomaticDebit(action: { type: string; payload: AutomaticDebitParams }) {
  try {
    const res: any = yield call(getAutomaticDebit, action.payload)

    if (!res || !res.data || typeof res.total !== 'number') {
      throw new Error('Invalid API response')
    }

    yield put(
      getAutomaticDebitSuccess({
        items: res.data,
        total: res.total,
      }),
    )
  } catch (err: any) {
    console.error('Error fetching data:', err)
    yield put(getAutomaticDebitFailure())
  }
}

function* watchFetchAutomaticDebit() {
  yield takeLatest(BtgActionsTypes.LIST_AUTOMATIC_DEBIT_REQUEST, fetchAutomaticDebit)
}

export default function* btgSagas() {
  yield all([fork(watchFetchAutomaticDebit)])
}
