import { notificationReducer } from './notification/index'
import { IApplicationState } from '.'
import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { toastrReducer } from './toastr'
import { authReducer } from './auth'
import { negotiationsReducer } from './repasse/negotiations'
import { permissionsReducer } from './permissions'
import { modulesConfigReducer } from './modules_config'
import { rolesReducer } from './roles'
import { userReducer } from './user'
import { previasReducer } from './previas'
import { retencaoReducer } from './inadimplencia'
import { antecipayReducer } from './antecipay'
import { comunicacaoReducer } from './comunicacao'
import { assemblyReducer } from './assembly'
import { assemblyMotorsReducer } from './assembly/motors'
import { insuranceReducer } from './insurance'
import { insuranceSaleConsultantsReducer } from './insuranceSaleConsultants'
import { whatsAppReducer } from './whats_app'
import { lpUnityReducer } from './lp_unity'
import { caasReducer } from './caas_digital'
import { cotaEquityReducer } from './cota-equity'
import { salesMachineReducer } from './sales_machine'
import { versionAppReducer } from './versioningApp'
import { postSalesReducer } from './post_sales'
import { recorrenciaReducer } from './recorrencia'
import { contemplayReducer } from './contemplay'
import { assuncaoReducer } from './assuncao'
import { btgReducer } from './btg'

export const rootReducer = (history: any) =>
  combineReducers<IApplicationState>({
    router: connectRouter(history),
    auth: authReducer,
    toastr: toastrReducer,
    negotiations: negotiationsReducer,
    notifications: notificationReducer,
    permissions: permissionsReducer,
    modulesConfig: modulesConfigReducer,
    roles: rolesReducer,
    user: userReducer,
    previas: previasReducer,
    retencao: retencaoReducer,
    antecipay: antecipayReducer,
    comunicacao: comunicacaoReducer,
    assembly: assemblyReducer,
    assemblyMotors: assemblyMotorsReducer,
    insurance: insuranceReducer,
    insuranceSales: insuranceSaleConsultantsReducer,
    whatsApp: whatsAppReducer,
    lpUnity: lpUnityReducer,
    caasDigital: caasReducer,
    cotaEquity: cotaEquityReducer,
    salesMachine: salesMachineReducer,
    versionApp: versionAppReducer,
    postSales: postSalesReducer,
    recorrencia: recorrenciaReducer,
    contemplay: contemplayReducer,
    assuncao: assuncaoReducer,
    btg: btgReducer,
  })
