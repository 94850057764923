import { AutomaticDebitData } from './interface'

export const BtgActionsTypes = {
  LIST_AUTOMATIC_DEBIT_REQUEST: '@@btg/LIST_AUTOMATIC_DEBIT_REQUEST',
  LIST_AUTOMATIC_DEBIT_SUCCESS: '@@btg/LIST_AUTOMATIC_DEBIT_SUCCESS',
  LIST_AUTOMATIC_DEBIT_FAILURE: '@@btg/LIST_AUTOMATIC_DEBIT_FAILURE',
}

export interface IBtgState {
  automaticDebitData: {
    error: boolean
    loading: boolean
    total: number
    items?: AutomaticDebitData[]
  }
}
