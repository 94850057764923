import React, { lazy } from 'react'
import { MenuItems } from 'routes/Routes'
import * as Icons from '@material-ui/icons'
import { buildRouteAndSubRoute } from 'core/dynamic-component'
import { ASSUNCAO_RESOURCE } from 'shared/resources'

const AssuncaoList = lazy(() => import('pages/Assuncao/List/'))
const Blacklist = lazy(() => import('pages/Assuncao/Blacklist/'))

export const AssuncaoRoutes: MenuItems[] = [
  {
    path: '',
    name: 'Assuncao',
    iconComponent: Icons.List,
    menus: [
      {
        path: `${ASSUNCAO_RESOURCE}`,
        name: 'Lista de Assuncao',
        component: AssuncaoList,
        exact: true,
        menus: [],
      },
      {
        path: `${ASSUNCAO_RESOURCE}/blacklist`,
        name: 'Blacklist',
        component: Blacklist,
        exact: true,
        menus: [],
      },
    ],
  },
]

/* eslint-disable-next-line react/display-name */
export default () => <>{buildRouteAndSubRoute(AssuncaoRoutes)}</>
