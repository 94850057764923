import React, { lazy } from 'react'
import { MenuItems } from 'routes/Routes'
import * as Icons from '@material-ui/icons'
import { buildRouteAndSubRoute } from 'core/dynamic-component'
import { DISTRIBUICAO_LEADS_RESOURCE } from 'shared/resources'

const LeadsDistributionDistributionPage = lazy(() => import('pages/LeadsDistribution/Distribution'))
const LeadsDistributionTrackingPage = lazy(() => import('pages/LeadsDistribution/Tracking'))
const LeadDistributionListPage = lazy(() => import('pages/LeadsDistribution/List'))

export const DistribuicaoLeadsRoutes: MenuItems[] = [
  {
    path: '',
    name: 'Distribuição de Leads',
    iconComponent: Icons.Public,
    menus: [
      {
        path: `${DISTRIBUICAO_LEADS_RESOURCE}/acompanhamento`,
        name: 'Acompanhamento',
        component: LeadsDistributionTrackingPage,
        exact: true,
        menus: [],
      },
      {
        path: `${DISTRIBUICAO_LEADS_RESOURCE}/distribuicao`,
        name: 'Distribuição',
        component: LeadsDistributionDistributionPage,
        isSubRoute: true,
        menus: [],
      },
      {
        path: `${DISTRIBUICAO_LEADS_RESOURCE}/lista`,
        name: 'Lista',
        component: LeadDistributionListPage,
        isSubRoute: true,
        menus: [],
      },
    ],
  },
]

export default function DistribuicaoLeadsRoutesComponent() {
  return <>{buildRouteAndSubRoute(DistribuicaoLeadsRoutes)}</>
}
